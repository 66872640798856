.page-container {
  display: flex;
  margin-left: 129px;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 25vh;
  background-color: #87CEFA;
  box-sizing: border-box;
  z-index: 1;
  padding-top: 20px;
}

.content-container {
  flex-grow: 1;
  padding: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: ruby;
}

.sensordata-container {
  flex-grow: 1;
  padding: 20px;
  text-align: center;
  align-items: center;
  display: grid;
  height: 100%;
  width: 70%;
}

.tabs {
  display: flex;
  flex-direction: column;
}

.tabs button {
  background-color: #87CEFA;
  color: #fff;
  padding: 30px 78px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.tabs button:hover {
  background-color: #6495ED;
}

.MapWithDrawing-map {
  position: fixed;
  text-align: center;
  height: 50vh;
  width: 50vw;
}
